import React, { useEffect, useRef } from "react";
import { Tokens } from "@okta/okta-auth-js";
import OktaSignIn, { OktaSignInConfig } from "@okta/okta-signin-widget";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";

export interface OktaSignInWidgetProps
  extends Pick<OktaSignInConfig, "baseUrl" | "clientId" | "redirectUri"> {
  onSuccess: (tokens?: Tokens) => void;
  onError: (error: Error) => void;
}

const OktaSignInWidget = ({
  baseUrl,
  clientId,
  onError,
  onSuccess,
  redirectUri,
}: OktaSignInWidgetProps) => {
  const widgetRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (widgetRef.current) {
      const config = {
        baseUrl,
        clientId,
        redirectUri,
        logo: "/img/GCPAT_Logo_H_Blue_CMYK.png",
        authParams: {
          // If your app is configured to use the Implicit flow
          // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
          // you will need to uncomment the below line
          pkce: false,
        },
        i18n: {
          en: {
            // Labels
            "primaryauth.title": "Verifi Portal",
            "primaryauth.username.placeholder": "Email Address",
            "primaryauth.username.tooltip": "Email Address",
            "primaryauth.password.placeholder": "Password",
            "primaryauth.submit": "Login to Verifi Portal",
            // Errors
            "error.username.required": "Please enter an Email Address",
            "error.password.required": "Please enter a Password",
            "errors.E0000004": "Sign in failed!",
          },
        },
      };
      const widget = new OktaSignIn(config);
      widget
        .showSignInToGetTokens({
          el: widgetRef.current,
        })
        .then(onSuccess)
        .catch(onError);

      return () => {
        if (widget) {
          widget.remove();
        }
      };
    }
    return undefined;
  }, [baseUrl, clientId, onError, onSuccess, redirectUri]);

  return <div ref={widgetRef} />;
};

export default OktaSignInWidget;
