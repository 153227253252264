import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    position: "absolute",
    top: "0",
    right: "0",
    left: "0",
    bottom: "0",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: theme.palette.background.paper,
    },
  },
  imageDiv: {
    [theme.breakpoints.up("md")]: {
      backgroundImage: "url(img/truck.jpg)",
      backgroundSize: "cover",
      height: "100vh",
    },
    [theme.breakpoints.up("lg")]: {
      width: "70vw",
    },
    [theme.breakpoints.only("md")]: {
      width: "60vw",
    },
  },
  signinWidget: {
    height: "100vh",
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(20),
      width: "30vw",
    },
    [theme.breakpoints.only("sm")]: {
      marginRight: theme.spacing(25),
      marginLeft: theme.spacing(19),
    },
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(10),
      width: "100vw",
    },
    [theme.breakpoints.only("md")]: {
      marginTop: theme.spacing(6),
      width: "40vw",
    },
  },
}));

export default useStyles;
