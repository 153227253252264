import React, { useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
// External Components
import { Tokens } from "@okta/okta-auth-js";
// Internal Component
import { useAnalytics } from "@vitm/components";
import Login from "../components/Login";
import { OktaSignInWidgetProps } from "../components/Login/SignInWidget";

export default (
  signInProps: Pick<
    OktaSignInWidgetProps,
    "baseUrl" | "clientId" | "redirectUri"
  >,
) => {
  const [authorized, isAuthorized] = useState<null | boolean>(null);
  const { oktaAuth, authState } = useOktaAuth();

  const checkAuthentication = () => {
    const authenticated =
      !authState.isPending &&
      !!authState.isAuthenticated &&
      !!authState.accessToken &&
      // for some reason isAuthenticated returns true even though the token is expired?
      !oktaAuth.tokenManager.hasExpired(authState.accessToken);

    if (!authenticated) {
      isAuthorized(authenticated);
    }
  };

  useEffect(() => {
    checkAuthentication();
  });

  const onSuccess = async (tokens?: Tokens) =>
    oktaAuth.handleLoginRedirect(tokens);

  useAnalytics("Login Page");

  // FIXME: This doesn't seem to be triggered on incorrect password. Not sure
  // if we should be notifying the user here?
  const onError = (err: Error) => window.Raven?.captureException(err);

  if (authorized === null) return null;

  return <Login {...signInProps} onSuccess={onSuccess} onError={onError} />;
};
