import React, { lazy, Suspense } from "react";
// External Components
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ThemeProvider } from "@mui/material/styles";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import {
  OnAuthRequiredFunction,
  RestoreOriginalUriFunction,
} from "@okta/okta-react/bundles/types/OktaContext";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { FlagsProvider } from "flagged";
// Internal Components
import { theme, ErrorBoundary, LoadingBox } from "@vitm/components";
import { LOGIN_PATH, OKTA_CALLBACK_PATH, ROOT_PATH } from "./paths";
import Login from "./screens/Login";
// Lazy imports
const Root = lazy(() => import("./screens/Root"));

const redirectUri = window.location.origin + OKTA_CALLBACK_PATH;
const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_ISSUER || "",
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID || "",
  redirectUri,
  postLogoutRedirectUri: window.location.origin + LOGIN_PATH,
  ...(process.env.REACT_APP_OKTA_SEND_TOKEN_TO_SERVER === "yes" && {
    scope: ["openid", "x-gcp-verifi-itm", "email"],
  }),
  pkce: false,
});

const renderLogin = () => {
  if (
    !process.env.REACT_APP_OKTA_BASE_URL ||
    !process.env.REACT_APP_OKTA_CLIENT_ID
  ) {
    throw new Error("Missing Okta configuration");
  }

  return (
    <Login
      baseUrl={process.env.REACT_APP_OKTA_BASE_URL}
      clientId={process.env.REACT_APP_OKTA_CLIENT_ID}
      redirectUri={redirectUri}
    />
  );
};

const flagFeatures = {
  devEnvironment: process.env.REACT_APP_HIDE_FLAGGED_FEATURES !== "true",
};

const AppRoutes = () => {
  const history = useHistory();
  const onAuthRequired: OnAuthRequiredFunction = () => {
    history.push(LOGIN_PATH);
  };
  const onRestoreOriginalUri: RestoreOriginalUriFunction = (
    _oktaAuth,
    originalUri,
  ) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <Suspense fallback={<LoadingBox />}>
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={onAuthRequired}
        restoreOriginalUri={onRestoreOriginalUri}
      >
        <Switch>
          <Route path={LOGIN_PATH} render={renderLogin} />
          <Route path={OKTA_CALLBACK_PATH} component={LoginCallback} />
          <SecureRoute path={ROOT_PATH} component={Root} />
        </Switch>
      </Security>
    </Suspense>
  );
};

export default () => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <ThemeProvider theme={theme}>
      <FlagsProvider features={flagFeatures}>
        <CssBaseline />
        <ErrorBoundary>
          <Router>
            <AppRoutes />
          </Router>
        </ErrorBoundary>
      </FlagsProvider>
    </ThemeProvider>
  </LocalizationProvider>
);
