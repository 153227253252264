import React from "react";
// External Components
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
// Internal Components
import SignInWidget, { OktaSignInWidgetProps } from "./SignInWidget";
import useStyles from "./useStyles";

const Login = (signInProps: OktaSignInWidgetProps) => {
  const classes = useStyles();
  return (
    <Paper square>
      <Grid
        container
        direction="column"
        alignItems="flex-end"
        className={classes.container}
      >
        <div className={classes.imageDiv} />
        <Grid className={classes.signinWidget}>
          <SignInWidget {...signInProps} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Login;
