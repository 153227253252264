export const LOGIN_PATH = "/login";
export const OKTA_CALLBACK_PATH = "/implicit/callback";
// NOTE These params need to be optional so secureRoute can correctly route
export const ROOT_PATH = "/:page?/:accountId?/:otherParams*";
// Truck Log
export const TRUCK_LOG_ROOT = "/trucklog";
export const TRUCK_LOG_PATH = `${TRUCK_LOG_ROOT}/:accountId?`;
// Ticket Log
export const TICKET_LOG_ROOT = "/ticketlog";
export const TICKET_LOG_PATH = `${TICKET_LOG_ROOT}/:accountId/:pageNumber`;
// Billing
export const BILLING_ROOT = "/billing";
export const BILLING_PATH = `${BILLING_ROOT}/:accountId?`;
// Error
export const ERROR_PATH = "/error/:accountId?";
